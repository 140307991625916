import { useContext } from "react";
import { GameStateContext } from "../contexts/GameStateContext";

const Key = (props) => {
    const game = useContext(GameStateContext);

    let classNames = ['key-button'];
    let disabled = false;
    let handler = () => {
        game.revealFunc(props.letter);
    };

    if (game.gameState.alphabet.used.includes(props.letter)) {
        disabled = true;
        classNames.push('disabled');
    }

    return (
        <a className={classNames.join(' ')} onClick={disabled ? null : handler}>{props.letter}</a>
    )
}

export default Key;

class Dictionary {
    constructor() {
        this.categories = [];
        this.words = [];
    }

    init(json) {
        this.categories = json.categories;
        this.words = json.words;
    }

    random() {
        return this.words[Math.floor(Math.random() * this.words.length)];
    }
}

export default Dictionary;

import Keyboard from "./components/Keyboard";
import Stats from "./components/Stats";
import Word from "./components/Word";
import { GameStateProvider } from "./contexts/GameStateContext";

function App() {
  return (
    <div className="App">
      <GameStateProvider>
        <div className="wrapper">
          <h1>Бесеница</h1>
        </div>
        <div className="wrapper">
          <Word />
        </div>
        <div className="wrapper">
          <Keyboard />
        </div>
        <div className="wrapper">
          <Stats />
        </div>
      </GameStateProvider>
    </div>
  );
}

export default App;

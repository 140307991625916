import Alphabet from "./Alphabet";
import Dictionary from "./Dictionary";
import Player from "./Player";

class GameState {
    constructor() {
        this.reset();
    }

    getAlphabet() {
        return this.alphabet;
    }

    getPlayer() {
        return this.player;
    }

    getDictionary() {
        return this.dictionary;
    }

    getWord() {
        if (this.word === null) {
            this.word = this.dictionary.random();
            this.realWord = this.word.word.toLowerCase();
            this.hiddenWord = this.hide(this.realWord);
        }

        return this.hiddenWord;
    }

    hide(word) {
        let newWord = "";

        newWord += word.split('').map((letter) => {
            const regex = new RegExp("[" + this.alphabet.getLetters().join('') + "]");
            if (letter.match(regex)) {
                return "_";
            } else {
                return letter;
            }
        }).join('');

        return newWord;
    }

    reveal(letter) {
        if (this.hasWon || this.hasLost) {
            return;
        }

        const split = this.realWord.split('');
        const hiddenSplit = this.hiddenWord.split('');
        let found = false;

        for (let i in split) {
            if (split[i] === letter) {
                hiddenSplit[i] = letter;
                found = true;
            }
        }

        if (found === false) {
            this.player.lives--;
        }

        this.alphabet.used.push(letter);
        this.hiddenWord = hiddenSplit.join('');

        this.evaluate();
    }

    evaluate() {
        if (this.hiddenWord === this.realWord) {
            this.win();
        }

        if (this.player.lives < 1) {
            this.lose();
        }
    }

    win() {
        this.hasWon = true;
        if (window.confirm(`Браво! Думата беше ${this.realWord.toUpperCase()}. Искаш ли да пробваш пак?`)) {
            this.reset();
        }
    }

    lose() {
        this.hasLost = true;
        this.alphabet.used = [...this.alphabet.letters];
        this.hiddenWord = this.realWord;
        if (window.confirm(`Не позна! Думата беше ${this.realWord.toUpperCase()}. Ще пробваш ли пак?`)) {
            this.reset();
        }
    }

    reset() {
        this.alphabet = new Alphabet();
        this.player = new Player();
        if (this.dictionary === undefined || this.dictionary === null) {
            this.dictionary = new Dictionary();
        }

        this.hasWon = false;
        this.hasLost = false;

        this.word = null;
        this.realWord = null;
        this.hiddenWord = null;
    }
}

export default GameState;

import { useContext } from "react";
import { GameStateContext } from "../contexts/GameStateContext";

const Word = () => {
    const game = useContext(GameStateContext);

    if (game.getLoading()) {
        return (
            <h3>Loading...</h3>
        )
    }

    return (
        <h3>{game.getGameState().getWord()}</h3>
    )
}

export default Word;

import { GameStateContext } from "../contexts/GameStateContext";

const { useContext } = require("react")

const Stats = () => {
    const game = useContext(GameStateContext);

    if (game.loading) {
        return;
    }

    let category;
    game.gameState.dictionary.categories.forEach((v, idx) => {
        if (v.id === game.gameState.word.category_id) {
            category = v;
        }
    })

    return (
        <div className="stats">
            <div className="category">Избраната категория е: <strong>{category.name}</strong>.</div>
            <div className="lives">Остават ти <strong>{game.gameState.player.lives}</strong> опита.</div>
        </div>
    )
}

export default Stats;

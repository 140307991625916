import React, { useContext, useEffect, useMemo, useState } from "react";
import GameState from "../components/GameState";

export const GameStateContext = React.createContext({
    gameState: null,
    loading: true,
    revealFunc: (letter) => {}
});

export const useGameState = () => useContext(GameStateContext);

class GameStateProviderValue {
    constructor({
        gameState,
        loading,
        revealFunc
    }) {
        this.gameState = gameState;
        this.loading = loading;
        this.revealFunc = revealFunc
    }

    getGameState() {
        return this.gameState;
    }

    getLoading() {
        return this.loading;
    }

    reveal(letter) {
        return this.revealFunc(letter);
    }
}

export const GameStateProvider = ({ children }) => {
    const [loading, setLoading] = useState(true);
    const [gameState, setGameState] = useState(new GameState());
    const [dirty, setDirty] = useState(false);

    const loadDictionary = async () => {
        const response = await fetch('/dictionary.json');
        const json = await response.json();
        return json;
    };

    useEffect(() => {
        const makeRequest = async () => {
            const dict = await loadDictionary();
            if (dict !== undefined) {
                gameState.dictionary.init(dict);
                setGameState(gameState);
                setLoading(false);
            }
        };

        if (loading === true) {
            makeRequest();
        }
    }, [loading]);

    const revealFunc = (letter) => {
        gameState.reveal(letter);
        setGameState(gameState);
        setDirty(!dirty);
    }

    const contextValue = useMemo(
        () => new GameStateProviderValue({ gameState, loading, revealFunc }),
        [gameState, loading, dirty]
    );

    return (
        <GameStateContext.Provider value={contextValue}>{children}</GameStateContext.Provider>
    );
}

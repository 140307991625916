import { useContext } from "react";
import { GameStateContext } from "../contexts/GameStateContext";
import Key from "./Key";

const Keyboard = () => {
    const game = useContext(GameStateContext);

    return (
        <ul className="keyboard">
            {game.getGameState().getAlphabet().getLetters().map((letter) => {
                return <li key={letter} className="key">
                    <Key letter={letter} />
                </li>;
            })}
            
        </ul>
    )
}

export default Keyboard;

class Alphabet {
    constructor(letters = null) {
        if (letters === null) {
            letters = [
                'а', 'б', 'в', 'г', 'д', 'е', 'ж', 'з', 'и', 'й', 'к', 'л', 'м', 'н', 'о',
                'п', 'р', 'с', 'т', 'у', 'ф', 'х', 'ц', 'ч', 'ш', 'щ', 'ъ', 'ь', 'ю', 'я'
            ]
        }

        this.letters = letters;
        this.used = [];
    }

    getLetters() {
        return this.letters;
    }
}

export default Alphabet;
